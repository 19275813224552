.sceneContainer {
  /* position: relative; */
  display: flex;
  justify-content: center;
  cursor: move;
  cursor: grab;
  align-items: center;
  overflow: hidden;
  /* height: 90%; */
  /* width: 90%; */
}
.wrapperContainer {
  position: relative;
  display: flex;
  /* justify-content: center;
        align-items: center; */
  overflow: hidden;
  flex-direction: column;
}
.fullScreenButtonContainer {
  position: absolute;
  bottom: 18px;
  right: 15px;
}
.iconFullscreen {
  height: 36px;
  width: 36px;
  color: #ffffff;
  /* color: aqua; */
}

.exitFullScreenButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: aqua;
}
#buttonsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border: 1px solid red;
}
#info {
  position: absolute;
  top: 10px;
  width: 100%;
  text-align: center;
  z-index: 100;
  display: block;
}
@media screen and (min-width: 769px) {
  .containerSizePreview {
    width: 100%;
    height: 80vh;
    margin: 0 auto;
  }
  .wrapperSizePreview {
    width: 100%;
    height: 80vh;
    margin: 0 auto;
  }
}
@media screen and (max-width: 769px) {
  .containerSizePreview {
    width: 100%;
    height: 60vh;
    margin: 0 auto;
  }
  .wrapperSizePreview {
    width: 100%;
    height: 60vh;
    margin: 0 auto;
  }
}

.containerSizeFull {
  /* position: fixed; */
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 100;
  height: 100vh;
}
.wrapperSizeFull {
  position: fixed;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 100;
  height: 100vh;
}
.h-800 {
  height: 800px;
}
.croppedInput {
  width: 105px;
}
.centered-element {
  height: 100px;
  width: 100px;
  position: absolute;
  left: 60%;
  margin-left: -50px;
  top: 95%;
  /* margin-top: -50px; */
}
.vr-button {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
    brightness(102%) contrast(102%);

  margin-bottom: 5px;
}
