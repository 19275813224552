.errorBoundaryWrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.mail {
  text-decoration: underline;
  color: #f46f82;
}
