@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Tinos&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@700&display=swap');
@layer utilities {
  .h-278 {
    height: 500px;
  }

  .h-300 {
    height: 300px;
  }
  .h-400 {
    height: 400px;
  }
  .h-250 {
    height: 250px;
  }
  .h-600 {
    height: 600px;
  }
  .w-600 {
    width: 600px;
  }
  .h-700 {
    height: 700px;
  }
  .w-800 {
    width: 800px;
  }
  .w-1000 {
    width: 1100px;
  }
  .h-800 {
    height: 800px;
  }
  .h-1000 {
    min-height: 1000px;
  }
  .homeBlock {
    min-height: 1300px;
  }
  .b {
    width: 25%;
    height: 30%;
    margin-left: 5rem;
  }
  :root {
    --gradient: linear-gradient(90deg, #ee6352, purple, #ee6352);
  
  }
  @keyframes bg-animation {
    0% {background-position: left}
    50% {background-position: right}
    100% {background-position: left}
  }
  .main-container {
    /* font-family: basic-sans, sans-serif; */
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.125em;
    line-height: 1.6;
    color: #333;
    background: #ddd;
    background-size: 300%;
    background-image: var(--gradient);
    animation: bg-animation 25s infinite;
  }

  
  @keyframes stroke-animation {
    0% {
        -webkit-text-stroke-color: #ee6352;
    }
    33% {
        -webkit-text-stroke-color: purple;
    }
    66% {
        -webkit-text-stroke-color: rgb(251 113 133);
    }
    100% {
        -webkit-text-stroke-color: #ee6352;
    }
  }
  .stroked-text {
    font-size: 50px;
    color: white;
    -webkit-text-stroke: 2px transparent;
    position: relative;
    display: inline-block;
}

.stroked-text::before {
    content: 'Animated Border';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-text-stroke: 2px transparent;
    background: linear-gradient(90deg, black, red, black);
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: animate-border 3s linear infinite;
}

@keyframes animate-border {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 0%;
    }
}
.main{
  height: 100vh;
}
  .homeBg {
    /* background-image: url('./assets/img/school-vr.jpg'); */
  }
  .homeBg {
    background: rgb(31 41 55);
    width: 100vw;
    padding: 3em;
    box-shadow: 0 0 3em rgba(0,0,0,.15);
  }
  
  .title {
    margin: 0 0 .5em;
    text-transform: uppercase;
    font-weight: 900;
    /* font-style: italic; */
    font-size: 14rem;
    letter-spacing: 7px;
    /* color: #ee6352; */
    line-height: 1.7;
    margin: 0;
    text-align: center;
    background-clip: text;
    color: transparent;
    background-size: 300%;
    background-image: var(--gradient);
    /* animation: bg-animation 25s infinite; */
    /* -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: rgb(251 113 133); */
    /* animation: stroke-animation 5s infinite; */
    /* animation: stroke-animation 14s infinite alternate; */
    /* -webkit-text-stroke: 1px rgb(230, 13, 13);  */
    /* Shadows to create the border effect */
    /* text-shadow: 
    -1px -1px 0 #22e90c,  
    1px -1px 0 #22e90c,
    -1px 1px 0 #22e90c,
    1px 1px 0 #22e90c;  */
    animation: gradientAnimation 6s infinite linear;
    transition: animation-duration 0.3s ease-in-out;
  }
  .animated-gradient-text:hover {
    animation-duration: 2s;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}
  /* .title:hover {
    background-position: right;
  } */
  
  
  .fun {
    color: white;
    border: 0;
    cursor: pointer;
    padding: .5em 1.25em;
    background: linear-gradient(90deg, #ee6352, purple, #ee6352);
    background-size: 300%;
    background-position: left;
    transition: background-position 350ms;
  }
  
  .fun:hover {
    background-position: right;
  }
  /* button */
.action-btn {
  margin: 100px;
  padding: 15px 40px;
  border: none;
  outline: none;
  color: #FFF;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 12px;
}
.action-btn::after {
  content: "";
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #333;
  left: 0;
  top: 0;
  border-radius: 10px;
}
/* glow */
.action-btn::before {
  content: "";
  background: linear-gradient(
    45deg,
    #FF0000, #FF7300, #FFFB00, #48FF00,
    #00FFD5, #002BFF, #FF00C8, #FF0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 600%;
  z-index: -1;
  width: calc(100% + 4px);
  height:  calc(100% + 4px);
  filter: blur(8px);
  animation: glowing 20s linear infinite;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
  opacity: 0;
}

@keyframes glowing {
  0% {background-position: 0 0;}
  50% {background-position: 400% 0;}
  100% {background-position: 0 0;}
}

/* hover */
.action-btn:hover::before {
  opacity: 1;
}

.action-btn:active:after {
  background: transparent;
}

.action-btn:active {
  color: #000;
  font-weight: bold;
}
  .businessBlock {
    width: 92%;
  }
  .active {
    transform: translateY(0);
    opacity: 1;
  }
  .chalkboard {
    font-size: 40px;
    color: #e8e8e8;
    font-family: 'Walter Turncoat', cursive;
    display: block;

    height: 86%;
    width: 64%;

    min-height: auto;
    /* margin:20px 0; */
    background-color: #497959;
    overflow-y: auto;
    box-shadow: -1px 2px 2px 0px #555, inset 0 0 10px 0 #555;
    border: #b78240 solid 10px;
    background-image: url('./assets/img/Chalkboard.txt');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  .chakboard:after {
    content: '';
    display: block;
    position: fixed;
    border-radius: 0.1em 0.1em 0 0.1em;
    width: 50px;
    height: 8px;
    background: #f1f1f1;
    top: 80%;
    margin-top: 75px;
    margin-right: 60px;
    right: 5%;
    box-shadow: inset 0 -4px 1px rgba(0, 0, 0, 0.3),
      -1px -1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 rgba(0, 0, 0, 0.3);
  }
}
input::placeholder {
  transition: all 0.2s ease-in;
}
footer {
  clear: both;
  position: relative;
}
option {
  border: rgb(39, 39, 39);
  outline: rgb(39, 39, 39);
}
input:focus::placeholder {
  opacity: 0;
  transform: translateY(-15px);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body > div:nth-child(6) > canvas {
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: 'Arvo', serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}


.parent-container {
  background: linear-gradient(to right, #ff7e5f, #feb47b); 
  padding: 20px;
  border-radius: 10px;
  display: inline-block;
  position: relative; 
}

.blue-container {
  background-color: blue;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  position: relative; 
  overflow: hidden;  
} 

.animated-gradient-text {
  font-size: 24px;
  font-weight: bold;
  color: transparent;
  background: linear-gradient(90deg, #ff7e5f, #feb47b, #ff7e5f);
  background-size: 200% 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative; /* Needed for absolute positioning to work */
  animation: gradientAnimation 3s infinite linear;
}

@keyframes gradientAnimation {
  0% {
      background-position: 0% 50%;
  }
  100% {
      background-position: 100% 50%;
  }
}

 {/* <div className=" mx-auto ">  */}
          {/* Replace with your content main-container */}
          {/* <div className=" homeBg h-278 relative max-w-full min-h-16 bg-no-repeat bg-center bg-cover h-auto  "> */}
            {/* <div className="parent-container">
              <div className="blue-container">
                  <span className="animated-gradient-text">Gradient Text</span>
              </div>
          </div> */}
            {/* <h1 className="title">Lectio</h1>
            <h1 className="stroked-text">Animated Border</h1>
            <button className="fun">Hover me!</button>
            <button className="btn">Gover Me!</button> */}
            {/* <div className="w-full min-h-full ">
              <div className="w-full h-278 opacity-40  absolute bg-black"></div>
              <div className="h-32  w-20 "></div>
              <div className="opacity-100 absolute flex flex-col items-center w-full">
                <h1 className=" font-tinos text-white text-center blockTextH font-semibold text-4xl p-3">
                  {homeInfo.headerSection.header[selectedLanguage]}
                </h1>
                <p className="font-gothic text-white text-center blockTextP p-3 text-xl">
                  {homeInfo.headerSection.info[selectedLanguage]}
                </p>
              </div>
            </div> */}
          {/* </div> */}
          {/* /End replace */}
        {/* </div> */}


        .dg.ac {display: none;}


        #canvas-container{
        position: relative;
          height:  100%;

        }
        #fluid{
          width:  100%;
          height:  100%;
        }
        .overlay {
          /* background: yellow; */
          height: 100%;
          /* position: relative;
          background-size: cover; */
        }

        .overlay h1 {
          text-transform: uppercase;
          font-weight: 900;
          font-size: 14rem;
          letter-spacing: 7px;
          line-height: 1.7;
          text-align: center;
          /* background-clip: text; */
          background-size: 300%;
          /* -webkit-text-stroke-width: 6px; */
          /* -webkit-text-stroke-width: medium;
          -webkit-text-stroke-color:  rgba(240, 241, 249, 0.187);  */
          text-shadow: 0 0 3px #FF0000, 0 0 5px #0000FF;

          width: 100%;
          height: 100%;
          background-color: #000;
          color: #fff;
          mix-blend-mode: multiply;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 16px;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        
          margin: 0;
          opacity: 0.9;
          box-shadow: 0 0 16px #000, 0 0 32px #000;

          display: flex;
          align-items: center;
          justify-content: center;
      }
      .sub-title{
        /* font-family: 'Tinos', serif; */
    /* font-family: 'Walter Turncoat', cursive; */
        font-size: 2.1em;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.9;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50%;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 9px;
      }
      .action-btn-container{
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.9;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 30%;
      }
        /* .projectTitleContainer{
          position: absolute;
          height:  100%;
          width: 100%;
          border: 2px solid #1b1a02;
          background-color: #fff;
          top:100px;
          left: 0;
        } */


        /* .projectTitleContainer::before {
          content: 'LECTIO';
          left: 0;
          top: 0;
          position: absolute;
          color: #222;
          background-color: #fff;
          padding: 1rem;
          font-family: Arial;
          z-index: 1;
          mix-blend-mode: screen;
          font-weight: 800;
          font-size: 8rem;
          letter-spacing: 1rem;
        } */
        /* .projectTitle{ */
          /* text-transform: uppercase;
          font-weight: 900; */
          /* font-style: italic; */
          /* font-size: 14rem; */
          /* font-size: 220px;
          letter-spacing: 7px;
          line-height: 0.9;
          margin: 0;
          text-align: center; */
          /* background-clip: text; */
          /* color: transparent; */
          /* color: #ee6352; */
          /* -webkit-text-stroke-width: 4px;
          -webkit-text-stroke-color:  rgba(251, 113, 133, 0.4);  */
           /*-webkit-text-stroke-color: black; */
          /* text-shadow:  */
              /* 1px 1px 2px rgba(0, 0, 0, 0.1),  
               1px 1px 2px rgba(0, 0, 0, 0.1),
              1px  1px 2px rgba(0, 0, 0, 0.1),
               1px  1px 2px rgba(0, 0, 0, 0.1); */
              
               /* first layer at 1px */
               /* -1px -1px 0px rgba(0, 0, 0, 0.5),
                0px -1px 0px rgba(0, 0, 0, 0.5),
                1px -1px 0px rgba(0, 0, 0, 0.5),
               -1px  0px 0px rgba(0, 0, 0, 0.5),
                1px  0px 0px rgba(0, 0, 0, 0.5),
               -1px  1px 0px rgba(0, 0, 0, 0.5),
                0px  1px 0px rgba(0, 0, 0, 0.5),
                1px  1px 0px rgba(0, 0, 0, 0.5),
               /* second layer at 2px */
                 /* -2px -2px 0px rgba(0, 0, 0, 0.5),
               -1px -2px 0px rgba(0, 0, 0, 0.5),
                0px -2px 0px rgba(0, 0, 0, 0.5),
                1px -2px 0px rgba(0, 0, 0, 0.5),
                2px -2px 0px rgba(0, 0, 0, 0.5),
                2px -1px 0px rgba(0, 0, 0, 0.5),
                2px  0px 0px rgba(0, 0, 0, 0.5),
                2px  1px 0px rgba(0, 0, 0, 0.5),
                2px  2px 0px rgba(0, 0, 0, 0.5),
                1px  2px 0px rgba(0, 0, 0, 0.5),
                0px  2px 0px rgba(0, 0, 0, 0.5),
               -1px  2px 0px rgba(0, 0, 0, 0.5),
               -2px  2px 0px rgba(0, 0, 0, 0.5),
               -2px  1px 0px rgba(0, 0, 0, 0.5),
               -2px  0px 0px rgba(0, 0, 0, 0.5),
               -2px -1px 0px rgba(0, 0, 0, 0.5); */
        /* } */