
.btn_liquid {
	display: inline-block;
	position: relative;
	width: 240px;
	height: 60px;
	border-radius: 27px;
	color: #fff;
	font: 700 14px/60px "Droid Sans", sans-serif;
	letter-spacing: 0.05em;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	margin-top: 25px;
	cursor: pointer;
  }
 
.btn_liquid .inner {
	position: relative;
	z-index: 2;
}
.btn_liquid canvas {

	position: absolute;
	top: -50px;
	right: -50px;
	bottom: -50px;
	left: -50px;
	z-index: 1; 
} 