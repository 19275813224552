.sceneContainer {
  display: flex;
  justify-content: center;
  cursor: move;
  cursor: grab;
  align-items: center;
  overflow: hidden;
  /* height: 90%;
    width: 90%; */
}

@media screen and (min-width: 769px) {
  .containerSizePreview {
    width: 100%;
    /* height: 80vh; */
  }
}
@media screen and (max-width: 769px) {
  .containerSizePreview {
    width: 100%;
    /* height: 60vh; */
  }
}

.containerSizeFull {
  /* position: fixed; */
  width: 100%;
  /* height: 100vh; */
}

.sceneMenu {
  top: 0px;
  left: 0px;
  padding-top: 5px;
  width: 190px;
  /* height: 100%; */
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.700717787114846) 0%,
    rgba(255, 255, 255, 0.6727065826330532) 0%
  );
  transition: all 1s;
  overflow-y: scroll;
}

.menuBlock {
  top: 15px;
  left: 15px;
  position: absolute;
  transition: all 1.5s;
}
.hiddenMenuBlock {
  transition: all 1.5s;
  left: -25px;
}
.closeButton {
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.planetButton {
  width: 85%;
  height: 30px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 30px;
  font-weight: bold;
}

.closeSceneMenu {
  left: -190px;
  transition: all 1s;
}

.openSceneMenu {
  transform: translate(0);
}
